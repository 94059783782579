$(function () {
	if($('.page-home').length > 0) {

		// cardSlideInit = function() {
	 //        $('.card-slide').hide()
	 //            .slice(0, 2)
	 //            .show();
	 //        $('.card-slide').eq(1).addClass('last-card-slide')
	 //    }

	 //    if($('.card-slide').length > 0) {
	 //        cardSlideInit();
	 //    }
	
		$('.jcarousel').jcarousel({
			wrap: 'circular'
		}).jcarouselAutoscroll();

		$('img.slide-image').on('click touchstart', function(e) {
				window.location = $(this).attr('link');
		});

		// $('.jcarousel-prev').jcarouselControl({
	 //        target: '-=1'
	 //    });

	 //    $('.jcarousel-next').jcarouselControl({
	 //        target: '+=1'
	 //    });

	}
});