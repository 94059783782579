$(function () {
    if($('.page-login').length > 0) {
        // $('.btn-login').click( function () {
        //     var param = {
        //         'username': $('#username').val(),
        //         'password': $('#password').val()
        //     };
        //     console.log(param);
        // });
    }
});