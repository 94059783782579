$(function () {
	if($('.page-registration').length > 0) {

		updateCardHeight = function () {
			$(".card-container").css('min-height', '');

			var card_max_height = Math.max.apply(null, $(".card-container .card .card-block").map(function (){
		        return $(this).height();
		    }).get());

			card_max_height = card_max_height + 40; // padding 20
			$(".card-container .card .card-block").css('min-height', card_max_height);
		}

		updateCardHeight();
    	$(window).resize(updateCardHeight);
	}
});