$(function () {
	if($('.page-schedule').length > 0) {
		
		$('#filter').keyup(function () {

            var rex = new RegExp($(this).val(), 'i');
            $('.searchable tr:not([role = "title-date"])').hide();
            
            $('.searchable tr').filter(function () {
                return rex.test($(this).text());
            }).show();

        });
		
	}
});