$(function() {
    updateContentHeight = function(){
        $('#page_content').css("min-height", "");
        page_header = $('nav.navbar.fixed-top').outerHeight();
        page_footer = $('.page-footer').outerHeight();
        window_height= $(window).height();
        doc_height= $(document).height();
        set_height = Math.max(window_height,doc_height) - page_header - page_footer;

        $('#page_content').css("min-height", set_height);
    };
    updateContentHeight();
    $(window).resize(updateContentHeight);

    var cur_bg = 0;
    var opacity = 1;

    fadeBgOut = function() {
        opacity = opacity - 0.2;
        $('.page-bg').css('opacity', opacity);
        if(opacity <= 0) {
            fadeBgIn();
        } else {
            // setTimeout(fadeBgOut, 100);
        }
    }

    fadeBgIn = function() {
        if(opacity == 0) { 
            $('.page-bg').css('background-image', 'url(' + bg_url[cur_bg = ++cur_bg % bg_url.length] + ')');
        }
        opacity = opacity + 0.2;
        
        if(opacity != 1){
            // setTimeout(fadeBgIn, 100);
        }
    }

    nextBg = function() {
        //fadeBgOut();
        $('.page-bg').css('background-image', 'url(' + bg_url[cur_bg = ++cur_bg % bg_url.length] + ')');

        setTimeout(nextBg, 5000);
    }
    
    $('.page-bg').css('background-image', 'url(' + bg_url[0] + ')');
    //setTimeout(nextBg, 5000);
});