$(function () {
  if($('.page-home2').length > 0) {

    updateSectionHeight = function(){
        $('.carousel-inner').css("height", "");
        $('section').css("min-height", "");
        page_header = $('nav.navbar.fixed-top').outerHeight();
        page_footer = $('.page-footer').outerHeight();
        window_height= $(window).height();
        doc_height= $(document).height();
        set_height = Math.min(window_height,doc_height) - page_header;
        // set_height = Math.max(window_height,doc_height) - page_footer;
        //alert(set_height + ' = ' + Math.min(window_height,doc_height) + ' - ' + page_header + ' - ' + page_footer);

        $('.carousel-inner').css("height", set_height - page_footer);
        $('section.content').css("min-height", set_height);
        $('section#banners').css('margin-top', page_header);
    };
    updateSectionHeight();
    //$(window).resize(updateSectionHeight());
    window.onresize = updateSectionHeight;

   // let section_height = $('#page_content').css("min-height");
   // $('#banner, #special_promotion').css("min-height", section_height);

   // $('#banner').css('background-image', 'url(' + section_bg[0] + ')');

   // $('.jcarousel').jcarousel({
   //    wrap: 'circular',
   //    animation: 'slow'
   //  }).jcarouselAutoscroll();
  }
});